<template>
  <div>
    <v-row>
      <v-col cols="auto" align-self="center">
        <v-btn icon to="/start" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-clipboard-list</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-light">
          ANWESENHEITSLISTE
        </h3>
        <h2 class="white--text font-weight-bold">
          NICHT ZUGEORDNETE PERSONEN
        </h2>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="5" class="text-center" align-self="center" v-if="datum">
        <v-dialog
          ref="beginndatum"
          v-model="adatum"
          :return-value.sync="datum"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="white" outlined large v-bind="attrs" v-on="on">
              <v-icon class="mr-2">mdi-calendar</v-icon>
              {{ parseDate(datum) }}
            </v-btn>
          </template>
          <v-date-picker
            locale="de"
            first-day-of-week="1"
            v-model="datum"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              :color="template.colors.primary"
              @click="adatum = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
              text
              :color="template.colors.primary"
              @click="
                $refs.beginndatum.save(datum)
                load_datum()
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="5" class="text-center" align-self="center">
        <v-btn
          :color="template.colors.primary"
          large
          @click="generatePDF()"
          :loading="generating"
          :disabled="anwesenheit.length == 0"
        >
          <v-icon class="mr-2">mdi-download</v-icon>
          PDF DOWNLOAD
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col cols="12">
        <v-card dark>
          <v-toolbar :color="template.colors.primary">
            <v-toolbar-title>Anwesende Personen</v-toolbar-title>
          </v-toolbar>
          <v-row
            style="min-height: 30vh;"
            justify="center"
            v-if="anwesenheit.length == 0"
          >
            <v-col align-self="center" class="text-center">
              <h3 class="font-weight-light">
                Es wurden derzeit noch keine Zuschauer erfasst.
              </h3>
            </v-col>
          </v-row>
          <v-list v-if="anwesenheit.length > 0">
            <v-list-item
              v-for="(m, index) in anwesenheit"
              :key="index"
              link
              :to="`/nicht-zugeordnet/${m.id}`"
            >
              <v-list-item-icon>
                <v-icon>mdi-check-bold</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ m.vorname }} {{ m.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-icon>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <vue-html2pdf
      :enable-download="true"
      :paginate-elements-by-height="900"
      pdf-content-width="1100px"
      :pdf-quality="2"
      :filename="`Anwesenheitsliste_Nicht_Zugeordnet_${datum}`"
      pdf-format="a4"
      pdf-orientation="landscape"
      ref="Anwesenheit"
      style="visibility: hidden;"
    >
      <section slot="pdf-content">
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900"
        />
        <div
          style="
            width: 1100px;
            padding-top: 30px;
            padding-left: 60px;
            padding-right: 60px;
            font-family: Roboto, sans-serif;
          "
        >
          <div style="text-align: center;">
            <h1>Anwesenheitsliste</h1>
            <h2>
              SPORTGELÄNDE
            </h2>
            <h3>Am {{ parseDate(datum) }}</h3>
          </div>
          <table
            style="width: 100%; margin-top: 30px; border: 2px solid #000000;"
          >
            <thead style="border-bottom: 2px solid #000000; text-align: left;">
              <th>#</th>
              <th>Name</th>
              <th>Adresse</th>
              <th>Telefon</th>
              <th>Zeitraum</th>
            </thead>
            <tbody>
              <tr
                v-for="(m, index) in anwesenheit"
                :key="index"
                style="border-bottom: 1px solid #000000;"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ m.vorname }} {{ m.name }}</td>
                <td>{{ m.adresse }}, {{ m.plz }} {{ m.ort }}</td>
                <td>{{ m.telefon }}</td>
                <td>{{ m.zutritt }} - {{ m.verlassen }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../../firebaseConfig'
import store from '../../../../store'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'Anwesenheitsliste',
  data() {
    return {
      anwesenheit: [],
      generating: false,
      datum: '',
      adatum: false,
    }
  },
  components: {
    VueHtml2pdf,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  mounted() {
    if (this.$route.params.datum) {
      this.datum = this.$route.params.datum
    } else {
      var today = new Date()
      this.datum =
        today.getFullYear() +
        '-' +
        this.addNull(today.getMonth() + 1) +
        '-' +
        this.addNull(today.getDate())
    }
    this.load_datum()
  },
  methods: {
    addNull(val) {
      if (val >= 10) {
        return val
      }
      return '0' + val
    },
    load_datum() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .where('datum', '==', this.datum)
        .get()
        .then((snap) => {
          this.anwesenheit = []
          snap.forEach((doc) => {
            var person = doc.data()
            this.anwesenheit.push(doc.data())
            this.anwesenheit[this.anwesenheit.length - 1].id = doc.id
            if (person.weitere_personen) {
              if (person.weitere_personen.length > 0) {
                var personen = person.weitere_personen
                var i, j
                for (i = 0; i < personen.length; i++) {
                  var name = personen[i].split(' ')
                  for (j = 2; j < name.length; j++) {
                    name[1] += ' ' + name[j]
                  }
                  if (person.spielerid) {
                    this.anwesenheit.push({
                      adresse: person.adresse,
                      name: name[1],
                      ort: person.ort,
                      plz: person.plz,
                      spielerid: person.spielerid,
                      teamid: person.teamid,
                      telefon: person.telefon,
                      verlassen: person.verlassen,
                      vorname: name[0],
                      zutritt: person.zutritt,
                    })
                  } else {
                    this.anwesenheit.push({
                      adresse: person.adresse,
                      name: name[1],
                      ort: person.ort,
                      plz: person.plz,
                      telefon: person.telefon,
                      verlassen: person.verlassen,
                      vorname: name[0],
                      zutritt: person.zutritt,
                    })
                  }
                  this.anwesenheit[this.anwesenheit.length - 1].id = doc.id
                }
              }
            }
          })
        })
    },
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    generatePDF() {
      if (this.anwesenheit.length > 0) {
        this.generating = true
        this.$refs.Anwesenheit.generatePdf()
        this.$emit('generated', true)
        setTimeout(() => {
          this.generating = false
        }, 1000)
      }
    },
  },
}
</script>
>
